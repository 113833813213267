<template>
  <section class="d-flex ml-auto mr-auto">
    <div
      v-if="chartData && orderedList.length > 0"
    >
      <pie-chart
        :chart-data="computedChartData"
        style="height: 200px"
      />
      <ul
        class="pie__legends mt-8 pl-0"
      >
        <li
          class="legend-holder"
          v-for="data in orderedList"
          :key="data.key"
          :ripple="false"
          :class="{
            link: linked,
            'd-flex': true,
            'align-center': true,
          }"
          @click="drillDetails(data)"
        >
          <span class="legend">
            <span class="pie__dot mr-2" :style="{ background: data.color }">
            </span>
            <s-text weight="medium" size="sm" class="mr-1 ml-1">
              {{ data.percent }}% -
            </s-text>
            <s-text
              weight="medium"
              size="sm"
              class="mr-1 ml-1"
              :style="{ color: data.color }"
            >
              {{ data.legend }}
            </s-text>
          </span>
        </li>
        <!-- <li
          @click="viewCategory"
          v-if="linked"
          class="d-flex align-center"
        >
          <span class="legend">
            <s-text weight="medium" size="sm" class="mr-1">Show all </s-text>
            <s-icon name="caret-down" color="dark" size="20px" />
          </span>
        </li> -->
      </ul>
    </div>

    <div v-if="!chartData" class="d-flex pa-8" style="height: 350px">
      <v-progress-circular
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
    </div>

    <empty-state
      v-if="chartData && orderedList.length === 0"
      message="No data available"
      hint="Please check back."
      style="height: 350px"
      class="ma-auto"
    />
  </section>
</template>

<script>
import PieChart from '@/components/charts/PieChart'
import EmptyState from '@/components/cards/EmptyState'
import { colours, coloursByTelco, coloursByCountry } from '@/utils/colours'

export default {
  name: 'DistributionAnalytics',
  components: {
    'pie-chart': PieChart,
    'empty-state': EmptyState,
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
    },
    linked: {
      type: Boolean,
      required: false,
      default: false,
    },
    chartColors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data () {
    return {
      color: this.chartColors.length === 0
        ? colours
        : this.chartColors,
    }
  },
  computed: {
    orderedList: function () {
      if (!this.chartData) return null

      const tempChartData = this.chartData
      const total = tempChartData.reduce(
        (acc, data) => acc + data.count,
        0
      )

      const dataset = []
      // process chart
      tempChartData.forEach((data, i) => {
        // for unknown
        let unknownCount = 0

        const _legend = !data.label
          ? 'Unknown'
          : data.label

        // check if we already have unknown
        const unknownIndex = dataset.findIndex(data => data.legend === 'Unknown')
        if (_legend === 'Unknown' && unknownIndex !== -1) {
          const unknownDataset = dataset[unknownIndex]
          dataset.splice(unknownIndex, 1)
          unknownCount = parseInt(unknownDataset.data)
        }

        dataset.push({
          legend: _legend,
          data: (data.count + unknownCount).toFixed(2),
          color: this.getContextColour(_legend, i),
          key: _legend,
          percent: (total > 0)
            ? (((data.count + unknownCount) / total) * 100).toFixed(1)
            : 0.0,
        })
      })

      const result = dataset
        .sort((a, b) => b.data - a.data)
        .slice(0, 10)
        .map((d, i) => {
          return {
            ...d,
            color: d.color,
          }
        })

      // if the sum of percentage is less than 1, return an empty array
      return result.reduce((total, each) => each.percent + total, 0) < 1
        ? []
        : result
    },
    computedChartData: function () {
      const computedChartData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      }

      this.orderedList.forEach(data => {
        computedChartData.labels.push(data.legend)
        computedChartData.datasets[0].data.push(data.data)
        computedChartData.datasets[0].backgroundColor.push(data.color)
      })
      return computedChartData
    },
  },
  methods: {
    drillDetails (data) {
      this.$emit('click', data)
    },
    getContextColour: function (key, index) {
      if (coloursByTelco[key.toLowerCase()]) {
        return coloursByTelco[key.toLowerCase()]
      }

      if (coloursByCountry[key.toLowerCase()]) {
        return coloursByCountry[key.toLowerCase()]
      }

      return this.color()[index]
    },
  },
}
</script>

<style lang="scss" scoped>
.pie__legends {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  list-style: none;

  li {
    margin: 10px 0;
    font-weight: 600;
    text-decoration: none !important;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &,
    & * {
      cursor: pointer;
    }
  }
}
.pie__dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.legend {
  border-radius: 30px;
  padding: 0 6px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--v-grayBg-base);
  }
}
</style>
