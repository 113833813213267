<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <empty-state
      v-if="data && orderedList.length === 0"
      message="No data available"
      hint="Please check back."
      style="height: 350px"
      class="ma-auto"
    />
    <v-container v-else class="px-8">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="6">
          <s-text weight="medium" color="gray" size="sm">
            Label
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Value
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Percentage
          </s-text>
        </v-col>
      </v-row>
      <v-row
        v-for="log in orderedList"
        :key="log.label"
        class="pa-0 ma-0 mb-3 app-card app-card-content"
      >
        <v-col class="d-flex flex-column" cols="6">
          <div>
            {{ log.label }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ log.count }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ log.percent }}%
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Loader from '@/components/cards/Loader'
import EmptyState from '@/components/cards/EmptyState'
export default {
  name: 'AnalyticDistributionList',
  components: {
    loader: Loader,
    'empty-state': EmptyState,
  },
  props: {
    getter: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      data (state, getters) {
        return getters[this.getter]
      },
    }),
    orderedList: function () {
      if (!this.data) return null

      const tempChartData = this.data
      const total = tempChartData.reduce(
        (acc, data) => acc + data.count,
        0
      )

      const dataset = []
      // process chart
      tempChartData.forEach((data, i) => {
        // for unknown
        let dataCount = data.count

        const _legend = !data.label
          ? 'Unknown'
          : data.label

        // check if we already have unknown
        const unknownIndex = dataset.findIndex(data => data.label === 'Unknown')
        if (_legend === 'Unknown' && unknownIndex !== -1) {
          const unknownDataset = dataset[unknownIndex]
          dataset.splice(unknownIndex, 1)
          dataCount += parseInt(unknownDataset.count)
        }

        dataset.push({
          label: _legend,
          count: dataCount.toFixed(2),
          percent: (total > 0)
            ? ((dataCount / total) * 100).toFixed(1)
            : 0.0,
        })
      })

      const result = dataset.sort((a, b) => b.count - a.count)

      // if the sum of percentage is less than 1, return an empty array
      const dataSetIsEmpty = result.reduce((total, each) => each.percent + total, 0) < 1
      if (dataSetIsEmpty) return []

      return result
    },
  },
  data () {
    return {
      isLoading: false,
    }
  },
}
</script>
